<template>
  <PrimaryModal v-bind="$attrs" v-on="$listeners">
    <v-card-text>
      <div class="text-wrap">
        <p>是否確認已付款</p>
        <p>
          並傳送訊息通知供應商？
        </p>
        <v-text-field
          placeholder="請輸入匯款帳號末5碼"
          :rules="rules"
          hide-details="auto"
          v-model="acountNo"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-card-text>
      <div class="d-flex justify-center">
        <v-btn color="primary" outlined min-width="100px" @click="close"
          >取消</v-btn
        >
        <v-btn min-width="100px" color="primary" @click="apply">確認</v-btn>
      </div>
    </v-card-text>
  </PrimaryModal>
</template>

<script>
import PrimaryModal from 'components/modal/primaryModal.vue';
export default {
  props: {},
  components: {
    PrimaryModal,
  },
  data: () => ({
    rules: [
      (value) =>
        value === '' ||
        value === null ||
        (typeof value === 'string' &&
          value.toString().length === 5 &&
          /^\d+$/.test(value)) ||
        '請輸入正確的帳號末5碼數字',
    ],
    acountNo: null,
  }),
  methods: {
    apply() {
      const rule = this.rules[0];
      const isValid = rule(this.acountNo) === true;
      if (isValid) {
        this.$emit('apply', this.acountNo);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.text-wrap
  text-align: center
  font-size: 1.5em
  color: #000

.v-input
    width: 160px
    margin: 0 auto

.v-card__text
  .d-flex
    gap: 10px
</style>
